<template>
  <div>
    <el-container>
      <el-header><h1>
        <div class="top"><image src="../assets/youhe.jpg"></image>友和文化办公系统入口</div>
      </h1></el-header>
      <el-main style="margin:10px auto;">
        <el-row style="overflow: auto; height:700px; padding-bottom: 100px;">
          <el-col :span="4" v-for="o in list" :key="o" :offset="0" >
            <el-card style="width:75%;padding:0px;height:280px;margin-bottom:10%;"  shadow="hover" :body-style="{ padding: '0px' }">
              <img @click="openUrl(o.modelUrl)" :src="o.modelImage" class="image">
              <span @click="openUrl(o.modelUrl)">{{o.modelName}}</span>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
      <el-footer>Copyright  2013-2020 友和文化  xsdoudou.cn All Rights Reserved. Administrator: Zhang Qinghe</el-footer>
    </el-container>
   
  </div>
</template>

<script>
import { portal } from "../api";
  export default {
    data() {
      return {
        list:[]
      };
    },
    mounted() {
      //校验用户是否已经登录
      this.status = localStorage.getItem("status")
      if(this.status){
        //跳转至列表
        console.info('tag', "已登录")
        // this.$router.push("/list");
      }else{
        //未登录
        console.info('tag', "未登录")
        this.$router.push("/login");
      }
    },
    async created() {
      let {data} = await portal();
      this.list = data;
    },
    methods: {
      openUrl(modelUrl){
        console.info('tag', modelUrl)
        window.location.href = modelUrl
      }
    },
  }
</script>
<style type = "text/css">  

body {
	background-image: url(../assets/bg2.jpg);
	text-align: center;
	background-size: 100% 100%;
	height: 100%;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
}
.top {
	background: #ffffff2e;
	width: 100%;
	position: absolute;
	top: 0;
	line-height: 80px;
	left: 0px;
	right: 0px;
	color: #fff;
	text-align: left;
	padding-left: 80px;
	font-size: 26px;
	font-weight: 600;
}
  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .el-header{
	  color: #fff;
    text-align: center;
    line-height: 60px;
  }
  .el-footer {
    margin-top: 10px;
	  color: #fff;

  }
  
  .el-aside { 
    color: #333;
    text-align: center;
    line-height: 200px;
  }
   
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }


  ::-webkit-scrollbar
{
	width: 0px;
	height: 0px;
	background-color: #F5F5F5;
}
 
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
 
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

</style>
