//1. 导入axios
import axios from 'axios'
//1.3 导入element.js中的Message
import { Message } from 'element-ui'

//2. 配置axios路径与请求超时
axios.defaults.baseURL='http://8.131.232.60:8888'
axios.defaults.timeout=3000

//3. 全局ajax错误处理
axios.interceptors.response.use(response=>{
    return response;
},error=>{
    Message.error(error);
    return Promise.reject(error);
})

//4. 方法区
/** 数据列表 */
export function portal(){
    return axios.get('/portal/');
}
/** 登录 */
export function login(param){
    return axios.post('/portal/login',param);
}
