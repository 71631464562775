

<template>
  <div>
    <div class="top"><font style="font-size:30px">友和文化</font>&nbsp;·&nbsp;红日初升，其道大光，乳虎啸谷，百兽震惶。
  纵有千古，横有八荒。江湖路远，来日方长。</div>
		<div class="content">
			<div class="login">
				<div class="title">登录入口</div>
				<div class="line">
					<img class="smallImg" src="../assets/icon1.png">
					<input placeholder="请输入账号" v-model="admin.username" type="text">
				</div>
				<div class="line">
					<img class="smallImg" src="../assets/icon2.png">
					<input placeholder="请输入密码" v-model="admin.password" type="password">
				</div>
				<button type="button" @click="loginFn" class="logBut">登&nbsp;&nbsp;录</button>
			</div>
		</div>
  </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex'
import { login } from "../api";
export default {
  data() {
    return {
      admin: {
        username: "",
        password: ""
      },
      status:this.$store.state.status
    };
  },
  mounted() {
    localStorage.removeItem('status');
    // //校验用户是否已经登录
    // this.status = localStorage.getItem("status")
    // if(this.status){
    //   //跳转至列表
    //   console.info('tag', "已登录")
    //   this.$router.push("/list");
    // }else{
    //   //未登录
    //   console.info('tag', "未登录")
    // }
  },
  methods: {
    async loginFn() {
      let {data} = await login(this.admin);
      console.info('data', data)
      if(data.login){
        //登录成功
        this.$router.push("/list");
        // this.$message.success(data.message)
        // this.updateUsername(true)
        localStorage.setItem("status",true)
      }else{
        //登录失败
        this.$message.error(data.message)
      }
    },
    ...mapMutations({
      updateUsername: 'user/updateUsername'
    })
  }
};
</script>

<style>
body {
	background-image: url(../assets/bg2.jpg);
	text-align: center;
	background-size: 100% 100%;
	height: 100%;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
}

.top {
	background: #ffffff2e;
	width: 100%;
	position: absolute;
	top: 0;
	line-height: 80px;
	left: 0px;
	right: 0px;
	color: #fff;
	text-align: left;
	padding-left: 80px;
	font-size: 26px;
	font-weight: 600;
}

.login {
	background: #fff;
	width: 21%;
	text-align: center;
	padding: 70px 2%;
	border-radius: 2px;
	position: absolute;
	top: 23%;
	right: 5%;
}

.title {
	font-weight: 600;
	font-size: 22px;
	color: #333;
	margin-bottom: 40px;
}

.line {
	border-bottom: 1px solid #d8d8d8;
	margin: 22px 1%;
	width: 96%;
}

.line input {
	border: none;
	padding: 0px 1%;
	margin: 1%;
	background: #fff;
	width: 84%;
	font-size: 16px;
	line-height: 30px;
	outline: none;
}

.line .smallImg {
	width: 26px;
	float: left;
	vertical-align: middle;
	margin-top: 1px;
}

.logBut {
	background: #8BC34A;
	padding: 10px 80px;
	border: none;
	color: #fff;
	margin-top: 40px;
	font-size: 16px;
	cursor:pointer;
}
</style>